import React from 'react';
import './team.css';
import logoOne from '../../images/1.png';
import logoTwo from '../../images/2.jpg';

const About = () => {
  return (
    <div
      className="team"
      id="team"
      style={{
        height: 'auto',
        backgroundColor: 'black',
        color: 'white',
        margin: 'auto',
        textAlign: 'center',
        paddingBottom: '30px',
      }}
    >
      <h1
        style={{
          fontSize: '60px',
          paddingTop: '20px',
          paddingBottom: '8px',
          margin: 'auto',
        }}
      >
        Team
      </h1>
      <div style={{ paddingBottom: '100px' }}>
        <p className="lab-bio">
          Ryder Labs is the exclusive one-stop-shop for NFT Smart Contract
          services we specialize in the brokering of relationships with
          celebrities and facilitating the development of collections from start
          to finish.
        </p>
      </div>
      <div></div>
      <div
        className="cards-container"
        // style={{
        //   display: 'flex',
        //   justifyContent: 'space-around',
        // }}
      >
        <div className="nft-card1">
          <img src={logoOne} alt="test logo" width="100%" height="" />

          <h1>Jojo Ryder</h1>
          <h2 className="ryderLabs">Owner/Co-founder</h2>
        </div>

        <div className="nft-card2">
          <img src={logoTwo} alt="test logo2" width="100%" height="" />
          <h1>Erica Mena</h1>
          <h2 className="ericaMena">Creator/ Co-founder</h2>
        </div>
      </div>
    </div>
  );
};

export default About;
