import React from 'react';
import fleche from '../../images/fleche.svg';
import './FAQ.css';
import { FAQData } from './FAQData';
// import { FAQs } from '../InfoSection/Data.js';

const FAQs = () => {
  // const { FAQs } = FAQ;

  return (
    <div className="faqs-section">
      <div className="img-background-faqs"></div>
      <div
        className="container"
        style={{
          height: '80vh',
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          flexDirection: 'column',
          margin: 'auto',
        }}
      >
        <div className="section-head section-head-s4 wide-auto-sm text-center">
          <h2 className="title title-s2 title-s2-alt ">
            FREQUENTLY ASKED QUESTIONS
          </h2>
        </div>
        <div className="nk-block">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8">
              <div className="accordion accordion-faq">
                {FAQData.map((item) => (
                  <div
                    key={item.Id}
                    className="accordion-item accordion-item-s4 bg-light-alt"
                  >
                    <p className="accordion-title collapsed">
                      {item.Question}
                      <span className={`accordion-icon accordion-icon-s2`}>
                        <img src={fleche} alt="" style={{ height: '3vh' }} />
                      </span>
                    </p>
                    <div className={`collapse`}>
                      <div className="accordion-content">
                        <p>{item.Answer}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQs;
