import React from 'react';
import { FaDiscord, FaInstagram, FaTwitter } from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll';
import {
  FooterContainer,
  FooterWrap,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  DiscordIconLink,
  InstagramIconLink,
  TwitterIconLink,
} from './FooterElements';

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };
  return (
    <FooterContainer>
      <FooterWrap>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/" onClick={toggleHome}>
              Mena
            </SocialLogo>
            <WebsiteRights>MenaNFT © 2022 All rights reserved.</WebsiteRights>
            <SocialIcons>
              <DiscordIconLink
                href="//discord.com/invite/menanft"
                target="_blank"
                aria-label="Discord"
              >
                <FaDiscord />
              </DiscordIconLink>
              <InstagramIconLink
                href="//www.instagram.com/iamerica_mena/"
                target="_blank"
                aria-label="Instagram"
              >
                <FaInstagram />
              </InstagramIconLink>

              <TwitterIconLink
                target="_blank"
                aria-label="Twitter"
                href="//twitter.com/theMenanft"
              >
                <FaTwitter />
              </TwitterIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
