import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const FooterContainer = styled.footer`
  padding-bottom: 60px;
  background-color: #000; //Black
  /* background-color: #b80f0a; //Crimson */
  /* background-color: #420d09; //Mahogany */
  /* background-color: #7e191b; //Vermilion */
  /* background-color: #7c0a02; //BarnRed */

  @media screen and (max-height: 1080) {
    padding-bottom: 140px;
  }
`;

export const FooterWrap = styled.div`
  padding: 50px 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
`;

export const FooterLinksContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const FooterLinksWrapper = styled.div`
  display: flex;
`;

export const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 30px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
  color: #fff;

  @media screen and (max-width: 420px) {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`;

export const FooterLinkTitle = styled.h1`
  font-size: 14px;
  margin-bottom: 16px;
`;

export const FooterLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;

  &:hover {
    color: #01bf71;
    transition: 0.3s ease-out;
  }
`;

export const SocialMedia = styled.section`
  max-width: 1000px;
  width: 100%;
`;

export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: 60px auto 0 auto;

  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
  }
`;

export const SocialLogo = styled(Link)`
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: bold;
`;

export const WebsiteRights = styled.small`
  color: #fff;
  margin-bottom: 16px;
  font-size: large;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    font-size: medium;
  }

  @media screen and (max-width: 420px) {
    flex-direction: column;
    font-size: small;
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
`;

export const DiscordIconLink = styled.a`
  color: #fff;
  font-size: 48px;
`;
export const InstagramIconLink = styled.a`
  color: #fff;
  font-size: 48px;
`;
export const TwitterIconLink = styled.a`
  color: #fff;
  font-size: 48px;
`;
