import styled from 'styled-components';
import { MdKeyboardArrowRight, MdArrowForward } from 'react-icons/md';

export const HeroContainer = styled.div`
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0px;
  height: 100vh;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 900px) {
    height: 90vh;
  }

  @media screen and (max-width: 767px) {
    height: 85vh;
  }
`;

export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media screen and (max-width: 900px) {
    height: 90vh;
  }
`;

export const VideoBg = styled.video`
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  background-size: cover;
  /* background: #232a34; */

  @media screen and (max-width: 900px) {
    object-fit: fill;
    height: 90vh;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const HeroContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeroH1 = styled.h1`
  color: #fff;
  font-size: 165px;
  text-align: center;
  padding-top: 180px;
`;

export const HeroH2 = styled.h1`
  color: #fff;
  font-size: 48px;
  text-align: center;
  padding-top: 0px;
`;

export const HeroP = styled.p`
  margin-top: 24px;
  color: #fff;
  font-size: 24px;
  text-align: center;
  max-width: 600px;
`;
export const HeroBtnWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 800px;
  /* padding-bottom: 300px; */

  @media screen and (max-width: 1924px) {
    padding-top: 550px;
  }
  @media screen and (max-width: 767px) {
    padding-top: 600px;
  }
  @media screen and (max-width: 767px) {
    padding-top: 200px;
  }
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;
