import React from 'react';
import Video from '../../videos/video.mp4';
import BackgroundVideo from '../../videos/videob.mp4';
import { HeroContainer, HeroBg, VideoBg } from './HeroElements';

function HeroSection() {
  return (
    <HeroContainer id="home">
      <HeroBg>
        <VideoBg
          id="vid"
          playsInline
          autoPlay
          loop
          muted
          src={Video}
          type="video/mp4"
        />

        <video
          style={{ objectFit: 'cover', width: '100vw', height: '85vh' }}
          playsInline
          autoPlay
          muted
          src={BackgroundVideo}
          type="video/mp4"
        />

        {/* <img
          src={Image}
          style={{ height: '100vh', width: '100vw', objectFit: 'contain' }}
          alt="background"
        /> */}
      </HeroBg>
    </HeroContainer>
  );
}

export default HeroSection;
