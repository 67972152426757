export const roadmapData = [
  {
    id: 1,
    name: 'Phase 1:',
    progress: '25% Sold out',
    desc: `- We will be donating 100k to the Global Giving Ukraine
        Crisis Relief Fund support humanitarian assistance in
        impacted communities in Ukraine and surrounding regions
        where Ukrainian refugees have fled.`,
  },
  {
    id: 2,
    name: 'Phase 2:',
    progress: '50% Sold out',
    desc: `- We will be giving away 75k worth of prizes in the
        discord server to our most active members.`,
  },
  {
    id: 3,
    name: 'Phase 3:',
    progress: '75% Sold out',
    desc: '- We will be giving away a a Board Ape Yacht Club NFT.',
  },
  {
    id: 4,
    name: 'Phase 4:',
    progress: '100% Sold out',
    desc: '- We will be giving away a custom Lamborgini Urus.',
  },
];
