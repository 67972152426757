import React, { useState } from 'react';
import Navbar from '../components/Navbar/index';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection/index';
import InfoSection from '../components/Roadmap';
// import { homeObjOne } from '../components/Roadmap/Data';
import Services from '../components/Faqs';
import Team from '../components/Team';

function Home() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <InfoSection />
      <Team />
      <Services />

      <Footer />
    </>
  );
}

export default Home;
