export const FAQData = [
  {
    Question: 'Question 1: What will the mint price be?',
    Answer: 'TBA',
    Id: 1,
  },
  {
    Question: 'Question 2: When is the launch date?',
    Answer: 'TBA',
    Id: 2,
  },
  {
    Question: 'Question 3: What network will this project be on?',
    Answer: 'Ethereum Mainnet',
    Id: 3,
  },
  {
    Question: 'Question 4: How many whitelist spots are there?',
    Answer: '3,000',
    Id: 4,
  },
];
