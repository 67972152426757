import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './Roadmap.css';

import { roadmapData } from './roadMapData';

function InfoSection() {
  return (
    <>
      <section className="roadmapSection" id="ROADMAP">
        <Container>
          <Row>
            <Col md={12}>
              <div className="roadmapHeading">
                <h1>Roadmap</h1>
              </div>
              <div className="roadmap">
                <ul class="timeline">
                  {roadmapData.map((roadMap) => {
                    return (
                      <li key={roadMap.id}>
                        <h1>{roadMap.name}</h1>
                        <h2>{roadMap.progress}</h2>
                        <div className="pointDescription">
                          <p>{roadMap.desc}</p>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default InfoSection;
